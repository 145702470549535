<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="清洗时间" prop="washTime">
      <a-input-number
        :min="0"
        placeholder="请输入清洗时间(以分钟为单位)"
        v-model="form.washTime"
        :precision="0"
        :class="$style.numberInput"
      />
    </a-form-model-item>
    <span :class="$style.tipText"
      >提示：清洗时间不足设置的时间，则会记录一次报警（清洗时间不足）</span
    >
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  carThresholdSetting,
  getCarThreshold,
} from '@/services/device-manage/setting';

@Component
export default class SettingForm extends Vue {
  @Prop({ type: String, default: () => {} }) deviceId;

  form = {
    idxDeviceId: '',
    idxProjectId: '',
    washTime: '',
  };
  rules = {
    washTime: [
      {
        required: true,
        message: '清洗时间不能为空',
      },
    ],
  };
  mounted() {
    this.initFrom();
  }
  async initFrom() {
    try {
      const data = await getCarThreshold(this.deviceId);
      this.form.washTime = data;
    } catch {
      return false;
    }
  }
  async getValue() {
    await this.$refs.form.validate();
    this.form.idxDeviceId = this.deviceId;
    try {
      await carThresholdSetting(this.form);
      this.$message.success('设置成功');
      return true;
    } catch (error) {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.numberInput {
  width: 100%;
}
.tipText {
  display: block;
  margin-top: 15px;
  font-size: 13px;
  color: var(--font-info);
}
</style>
