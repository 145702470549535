<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
        @change="initData"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: 1200 }"
      @change="handlePagination"
    >
      <template slot="url" slot-scope="text, record">
        <span v-for="(item, index) in record.url" :key="index">
          <x-oss-image
            v-if="item"
            basePath="/oss/iot/oss"
            :ossPath="item"
            @click="openImg(record.url, index)"
            class="table-img"
        /></span>
      </template>
    </a-table>
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Preview } from '@triascloud/x-components';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import RecordTable from '@/views/green-construction/components/mixins/record-table';
import {
  deviceRecord,
  // deviceTypeList,
} from '@/services/device-manage/device-detail';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    TabContainer,
  },
})
export default class CleaningLog extends mixins(RecordTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('carManagement.startTime'),
        dataIndex: 'startTime',
        ellipsis: true,
        width: 180,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('carManagement.endTime'),
        dataIndex: 'endTime',
        ellipsis: true,
        width: 180,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: `${this.$t('carManagement.duration')}(${this.$t(
          'greenConstruction.minutes',
        )})`,
        dataIndex: 'durationOfStay',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('carManagement.plateNumber'),
        ellipsis: true,
        dataIndex: 'licensePlateNumber',
      },
      {
        align: 'left',
        title: this.$t('carManagement.vehicleType'),
        ellipsis: true,
        dataIndex: 'vehicleType',
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.snapshots'),
        dataIndex: 'url',
        width: 300,
        ellipsis: true,
        scopedSlots: { customRender: 'url' },
      },
      {
        align: 'left',
        title: '清洗结果',
        ellipsis: true,
        dataIndex: 'alertStatus',
        customRender: txt => ['正常', '时间不足'][txt],
      },
      // {
      //   align: 'left',
      //   title: this.$t('carManagement.alarmStatus'),
      //   ellipsis: true,
      //   dataIndex: 'alarmSubType',
      //   customRender: text => (text ? this.exchangeValue(text) : '不报警'),
      // },
    ];
  }
  created() {
    // this.getTypeList();
  }
  async openImg(pictures, index) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    Preview.createModal({
      urls: abPath,
      index,
    });
  }
  getRecordList(queryParams, deviceGroup) {
    return deviceRecord(queryParams, deviceGroup);
  }
  // typeList = [];
  // async getTypeList() {
  //   try {
  //     this.typeList = await deviceTypeList(this.deviceGroup);
  //   } catch {
  //     return false;
  //   }
  // }
  // exchangeValue(value) {
  //   const idx = this.typeList.findIndex(v => v.value === value);
  //   return this.typeList[idx].label;
  // }
  deviceGroup = deviceTypeEnum.VC.deviceGroup;
}
</script>

<style lang="less" module></style>
